import { styled } from 'Theme/stitches.config';
import { CSS } from '@stitches/react';

import { getResizedImageFromEgmontCDN, ImageScalingTypes } from 'Shared/Common/ResizeImageEgmontCDN';
import PriceInfo from '../ProductCard/PriceInfo';
import GiftCardsCardModel from 'Models/GiftCard/GiftCardsCardModel.interface';
import { FocusVisibleStyle } from 'DesignSystem/Accessibility/Utils';

import { useAppSettings } from '../../../context/init-data.context';
import { useKexNavigate } from '../../../lib/router/UseKexNavigate';

type GiftCardCardType = {
  item: GiftCardsCardModel;
  selectedModel?: string;
  inEditMode?: boolean;
  css?: CSS;
  width?: number; //Will be 1/4 of the content area width if rendered in a Product card list
};

function GiftCardCard({ item, width, css }: GiftCardCardType) {
  const { name, nowPrice, wasPrice, image, link } = item;
  const { missingImage } = useAppSettings().imageSettings;
  const kexNavigate = useKexNavigate();

  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    if (link) {
      if (event.target instanceof HTMLAnchorElement) {
        // Prevent the default click event for the anchor tag
        event.preventDefault();
      }
      kexNavigate(link.href);
    }
  };

  return (
    <Card css={width ? { ...css, minW: width } : css} onClick={handleCardClick}>
      <ProductImageWrapper>
        <ProductImage
          src={
            image
              ? getResizedImageFromEgmontCDN(
                  image.src,
                  ImageScalingTypes.PRODUCT_CARD
                )
              : missingImage.src
          }
          alt={image ? image.alt : ''}
        />
      </ProductImageWrapper>

      <InfoWrapper>
        <TitleAndSubTitle>
          <CardLink href={link.href}>{name}</CardLink>
        </TitleAndSubTitle>

        <PriceInfo
          currentPrice={nowPrice}
          wasPrice={
            wasPrice && !wasPrice.isZero && wasPrice.price !== nowPrice.price ? wasPrice.priceWithSymbol : undefined
          }
        ></PriceInfo>
      </InfoWrapper>
    </Card>
  );
}
const CardLink = styled('a', {
  fontSize: '$fontSize100',
  fontFamily: 'fontBold',
  lineHeight: '$lh24',
  textOverflow: 'ellipsis',
  overflowX: 'hidden',
  '@bpMax480': {
    fontSize: '$fontSize75',
  },
  '&:focus-visible': {
    outline: 'none !Important',
    boxShadow: 'none !Important',
  },
});

const InfoWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  pt: 2,
  height: '100%',
});

const TitleAndSubTitle = styled('div', {
  mb: 2,
});

const Card = styled('article', {
  color: '$onSurface',
  cursor: 'pointer',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  w: 'auto',
  minW: 100,
  height: '100%',
  '&:hover': {
    [`& ${CardLink}`]: {
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
      textDecorationThickness: ' 2px',
    },
  },
  '&:has(a:focus-visible)': {
    ...FocusVisibleStyle,
  },
  '@bpMax720': {
    pb: 5,
  },
});

const ProductImageWrapper = styled('div', {
  backgroundColor: '$productCardBackground',
  position: 'relative',
  aspectRatio: '1/1',
  p: 0,
});

const ProductImage = styled('img', {
  height: '100%',
  width: '100%',
  objectFit: 'cover',
});

export default GiftCardCard;
